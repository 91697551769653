// This composable, which is applied only to tag and tagAsDates based calendarTypes, makes use of the client-state composable
// to detect values for startDay, firstWeek, and lastWeek from query params and localStorage. It returns nothing, but
// instantiates these values into Vuex state in the onboardingEventsCalendar module.

import { computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import useClientState from 'composables/client-state';
import * as moment from 'moment';

const useEventsCalendarSettings = ({ tag, events }) => {
  const { commit, dispatch } = useStore();
  const { getQueryParam, getLocalStorage, setLocalStorage } = useClientState();

  // Helper method for detecting numbers (truthy) and 0 (falsey)
  // eslint-disable-next-line no-restricted-globals
  const validNumerical = (value) => !isNaN(parseInt(value));

  // Computed values based on client-state
  const initViewMode = computed(() => {
    let viewModeInLocalStorage;
    if (tag) {
      viewModeInLocalStorage = getLocalStorage(`viewModeForTag${tag.id}`);
    } else {
      viewModeInLocalStorage = getLocalStorage('viewMode');
    }
    if (['grid', 'list'].includes(viewModeInLocalStorage)) return viewModeInLocalStorage;
    return 'grid';
  });

  const initStartDay = computed(() => {
    const startDayQueryParam = getQueryParam('start_day');
    const startDayForTagInLocalStorage = tag ? getLocalStorage(`startDayForTag${tag.id}`) : null;
    const startDayInLocalStorage = getLocalStorage('startDay');
    if (validNumerical(startDayQueryParam)) return startDayQueryParam;
    if (tag && validNumerical(startDayForTagInLocalStorage)) return startDayForTagInLocalStorage;
    if (validNumerical(startDayInLocalStorage)) return startDayInLocalStorage;
    return 0;
  });

  const initFirstWeek = computed(() => {
    if (tag) {
      const firstWeekQueryParam = getQueryParam('first');
      const firstWeekForTagInLocalStorage = getLocalStorage(`firstWeekForTag${tag.id}`);
      if (validNumerical(firstWeekQueryParam)) return firstWeekQueryParam;
      if (validNumerical(firstWeekForTagInLocalStorage)) return firstWeekForTagInLocalStorage;
    }
  });

  const initLastWeek = computed(() => {
    if (tag) {
      const lastWeekQueryParam = getQueryParam('last');
      const lastWeekForTagInLocalStorage = getLocalStorage(`lastWeekForTag${tag.id}`);
      if (validNumerical(lastWeekQueryParam)) return lastWeekQueryParam;
      if (validNumerical(lastWeekForTagInLocalStorage)) return lastWeekForTagInLocalStorage;
    }
  });

  const initCalendarType = computed(() => {
    if (tag) {
      const calendarTypeForTagInLocalStorage = getLocalStorage(`calendarTypeForTag${tag.id}`);
      if (['tag', 'tagAsDates'].includes(calendarTypeForTagInLocalStorage)) return calendarTypeForTagInLocalStorage;
      return 'tag';
    }
  });

  const initStartDate = computed(() => {
    if (tag) {
      const startDateForTagInLocalStorage = getLocalStorage(`startDateForTag${tag.id}`);
      if (moment(startDateForTagInLocalStorage, 'MM/DD/YY').format('MM/DD/YY') !== 'Invalid date') return startDateForTagInLocalStorage;
    }
  });

  // Calculate the range based on events and client-state values
  const weekRange = computed(() => {
    const dayValues = events.map(e => e.days_later);

    let earliestDay = dayValues[0] || 0;
    earliestDay = Math.floor((earliestDay + initStartDay.value) / 5) * 5;

    let latestDay = dayValues[dayValues.length - 1] || 0;
    latestDay = Math.floor((latestDay + initStartDay.value) / 5) * 5;

    if (initFirstWeek.value && initLastWeek.value) {
      const earliestWeek = Math.floor(earliestDay / -5);
      const latestWeek = (Math.floor(latestDay / 5)) + 1;
      return {
        firstWeek: Math.max(initFirstWeek.value, earliestWeek),
        lastWeek: Math.max(initLastWeek.value, latestWeek),
      };
    }
      earliestDay = Math.min(earliestDay, -10) - initStartDay.value;
      latestDay = Math.max(latestDay, 99);
      return {
        firstWeek: Math.floor(earliestDay / -5),
        lastWeek: Math.floor(latestDay / 5) + 1,
      };
  });


  onBeforeMount(() => {
    // As a note, this lifecycle hook does not conflict with any other registered onBeforeMount hooks in components using this composable
    // They will fire directly in sequence in the order that they are registered

    // Establish (or re-establish) localStorage values based on client-state
    setLocalStorage('startDay', initStartDay.value);
    if (tag) {
      setLocalStorage(`startDayForTag${tag.id}`, initStartDay.value);
      if (initFirstWeek.value) setLocalStorage(`firstWeekForTag${tag.id}`, initFirstWeek.value);
      if (initLastWeek.value) setLocalStorage(`lastWeekForTag${tag.id}`, initLastWeek.value);
    }

    // Establish values in onboardingEventsCalendar module
    commit('update', { module: 'onboardingEventsCalendar', key: 'viewMode', value: initViewMode.value });
    commit('update', { module: 'onboardingEventsCalendar', key: 'startDay', value: initStartDay.value });
    commit('update', { module: 'onboardingEventsCalendar', key: 'firstWeek', value: weekRange.value.firstWeek });
    commit('update', { module: 'onboardingEventsCalendar', key: 'lastWeek', value: weekRange.value.lastWeek });
    if (initStartDate.value) commit('update', { module: 'onboardingEventsCalendar', key: 'startDate', value: initStartDate.value });
    dispatch('onboardingEventsCalendar/changeCalendarType', { calendarType: initCalendarType.value });
  });
};

export default useEventsCalendarSettings;
