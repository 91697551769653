import Routes from 'lib/routes.js';

export default {
  presetCategory(category) {
    return {
      label: category.name,
      url: Routes.presetCategory(category),
    };
  },
};
