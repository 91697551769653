import { mapState } from 'vuex';
import Breadcrumbs from 'lib/breadcrumbs';

export default {
  data() {
    return {
      Breadcrumbs,
      freePairingTypes: ['standard', 'topic_based'],
      introMessageChanged: false,
    };
  },

  computed: {
    valid() {
      return this.isEmptyDeep(this.errors);
    },

    previouslyFreeCoffeeFeatureAccess() {
      return this.coffeeConfiguration.service_account.previouslyFreeCoffeeFeatureAccess;
    },

    // Keep the following free...() methods up to date with CoffeeConfiguration#standard_features_in_use
    freeGroupSize() {
      return parseInt(this.coffeeConfiguration.group_size, 10) === 2;
    },

    freePairingType() {
      return this.freePairingTypes.indexOf(this.coffeeConfiguration.pairing_type) >= 0;
    },

    freeIntroMessage() {
      return !(this.coffeeConfiguration.default_intro_type === 'custom' && this.introMessageChanged);
    },

    premiumGroupSize() {
      const { coffeeConfiguration, initialGroupSize, previouslyFreeCoffeeFeatureAccess } = this;

      if (previouslyFreeCoffeeFeatureAccess) return false;

      return coffeeConfiguration.pairing_type === 'standard'
        // eslint-disable-next-line eqeqeq
        && coffeeConfiguration.group_size != initialGroupSize
        // eslint-disable-next-line eqeqeq
        && !this.freeGroupSize;
    },

    premiumPairingType() {
      const { coffeeConfiguration, freePairingTypes, previouslyFreeCoffeeFeatureAccess } = this;

      if (coffeeConfiguration.pairing_type === 'standard' && previouslyFreeCoffeeFeatureAccess) return false;

      return !this.freePairingType;
    },

    premiumIntroMessage() {
      const { coffeeConfiguration, previouslyFreeCoffeeFeatureAccess } = this;
      if (previouslyFreeCoffeeFeatureAccess) return false;
      return !this.freeIntroMessage;
    },

    reachedConfigLimit() {
      return this.fromNew && !this.moreConfigsAllowed;
    },

    canStartTrial() {
      const { paidFeatureAccess, paidCoffeeTrialAllowed } = this.coffeeConfiguration.service_account;
      return !paidFeatureAccess && paidCoffeeTrialAllowed;
    },

    showClaimModal() {
      // usingPaidFeatures takes into account the service account of the viewing user.
      // This modal needs to check simply that the config is using a single non free feature.
      const paidAccountRequired = !(this.freeGroupSize && this.freePairingType && this.freeIntroMessage);
      return this.canEdit && this.coffeeConfiguration.claiming_allowed && paidAccountRequired;
    },

    usingPaidFeatures() {
      return this.premiumGroupSize || this.premiumPairingType || this.premiumIntroMessage;
    },

    saveRequiresTrial() {
      const { canStartTrial, reachedConfigLimit, usingPaidFeatures } = this;
      return canStartTrial && (reachedConfigLimit || usingPaidFeatures);
    },

    saveRequiresPayment() {
      const { paidFeatureAccess, paidCoffeeTrialAllowed } = this.coffeeConfiguration.service_account;
      const { usingPaidFeatures, reachedConfigLimit } = this;
      return (!paidFeatureAccess && !paidCoffeeTrialAllowed)
        && (reachedConfigLimit || usingPaidFeatures);
    },

    scrollElementID() {
      const { viewingFeature } = this;

      switch (viewingFeature) {
        case 'intro_message':
          return '#intro-message-form-item';
        case 'group_size':
        case 'categories':
        case 'lottery':
          return '#coffee-pairing-type';
        case 'frequency':
          return '#frequency-form-item';
        case 'gcal':
          return '#gcal-form-item';
        case 'welcoming_new_joiners':
          return '#welcoming-new-joiners';
        case 'selfies':
          return '#selfie-contest';
        default:
          return null;
      }
    },

    ...mapState([
      'coffeeConfiguration',
      'errors',
      'formSubmitted',
      'tour',
      'viewingFeature',
      'currentUser',
      'moreConfigsAllowed',
      'fromNew',
      'returnTo',
      'ref',
      'canEdit',
    ]),
  },

  watch: {
    // eslint-disable-next-line func-names
    'coffeeConfiguration.intro_message': function () {
      this.introMessageChanged = true;
    },

    // eslint-disable-next-line func-names
    'coffeeConfiguration.default_intro_type': function (newVal, oldVal) {
      if (newVal === 'custom') this.introMessageChanged = true;
    },
  },

  mounted() {
    Donut.Event.Bus.register(Donut.Event.VALIDATE_FORM, this.onValidate);

    this.$nextTick(() => {
      const { scrollElementID } = this;

      if (scrollElementID != null) {
        const $element = $(scrollElementID);
        if ($element.length > 0) {
          $('body').stop().animate({ scrollTop: ($element.offset().top - 50) }, '600', 'swing');
          $element.addClass('form-highlighted');
        }
      }
    });
  },

  beforeUnmount() {
    this.storeError(null);
    Donut.Event.Bus.deregister(Donut.Event.VALIDATE_FORM, this.onValidate);
  },

  methods: {
    submit(type) {
      const submit = () => this.$refs.form.submit(type);
      if (this.showClaimModal) {
        // TODO: Not ideal to pass a function through modals data. Update this.
        this.$store.commit('updateModal', {
          visible: true,
          subcomponent: 'coffee-form-modals-claim-config',
          data: { submit },
        });
      } else {
        submit();
      }
    },

    onValidate() {
      // Give all of our fields time to react to the validation
      this.$nextTick(() => {
        const $error = $('.has-error');
        const isEdit = this.coffeeConfiguration.id;
        if ($error.length > 0) {
          // If there's an error, switch to the tab with the error to display it, prioritizing the first tab.
          if (isEdit && ['channel_uid', 'category_name', 'group_size'].some(f => this.errors[f])) {
            this.changeTab('basics');
          } else if (isEdit && ['next_occurrence_on', 'time_zone_name', 'calendar_scheduling'].some(f => this.errors[f])) {
            this.changeTab('timing');
          } else {
            $('body').stop().animate({ scrollTop: ($error.offset().top - 50) }, '600', 'swing');
          }
        }
      });
    },
  },
};
