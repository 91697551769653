export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },

  computed: {
    role() {
      if (this.event.role) {
        return this.event.role.name;
      }
    },

    secondRole() {
      if (this.event.second_role) {
        return this.event.second_role.name;
      }
    },
  },
};
