import BuildingBlocks from 'lib/breadcrumbs/building-blocks';
import PresetCategories from 'lib/breadcrumbs/preset-categories';
import Presets from 'lib/breadcrumbs/presets';
import ChannelPrograms from 'lib/breadcrumbs/channel-programs';
import OnboardingBatches from 'lib/breadcrumbs/onboarding-batches';

export default {
  ...BuildingBlocks,
  ...PresetCategories,
  ...Presets,
  ...ChannelPrograms,
  ...OnboardingBatches,
};
