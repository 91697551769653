import { mapState } from 'vuex';

export default {
  computed: {
    welcomeMessageActive() {
      const { coffeeConfiguration, otherWelcomingConfigurations } = this;
      const welcomingNewJoiners = coffeeConfiguration.welcoming_new_joiners;

      return welcomingNewJoiners || otherWelcomingConfigurations.length > 0;
    },

    otherWelcomingConfigurations() {
      const { coffeeConfiguration, welcomingConfigurations } = this;
      return welcomingConfigurations.filter(cc => cc.id !== coffeeConfiguration.id);
    },

    computedWelcomingConfigurations() {
      const { coffeeConfiguration, otherWelcomingConfigurations } = this;
      const numOtherConfigs = otherWelcomingConfigurations.length;
      const welcomingNewJoiners = coffeeConfiguration.welcoming_new_joiners;

      const configs = otherWelcomingConfigurations.slice();
      if (welcomingNewJoiners || numOtherConfigs === 0) {
        configs.push(coffeeConfiguration);
      }

      return configs;
    },

    ...mapState([
      'coffeeConfiguration',
      'welcomingConfigurations',
    ]),
  },
};
