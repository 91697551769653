import Routes from 'lib/routes.js';

export default {
  preset(preset) {
    return {
      label: preset.name,
      url: Routes.presetEvents(preset),
    };
  },
};
