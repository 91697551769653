export default {
  formatMinutes(minutes, compact = false) {
    let hourInDay = Math.floor(minutes / 60);
    let period = 'am';

    if (hourInDay > 12) {
      if (hourInDay !== 24) {
        period = 'pm';
      }
      hourInDay -= 12;
    } else if (hourInDay === 12) {
      period = 'pm';
    } else if (hourInDay === 0) {
      hourInDay = 12;
    }

    if (compact) {
      return `${hourInDay}${period}`;
    }
    let minutesInHour = minutes % 60;
    if (minutesInHour < 10) {
      minutesInHour = `0${minutesInHour}`;
    }

    return `${hourInDay}:${minutesInHour}${period}`;
  },
};
