export default {
  batches(flowType, tag) {
    let url;
    if (tag) {
      url = `/${flowType.routeResource}/tag/${tag.id}/batches`;
    } else {
      url = `/${flowType.routeResource}/batches`;
    }
    return {
      label: `${flowType.primaryRole}s`,
      url,
    };
  },
};
