export default {
  data() {
    return {
      mounted: false,
    };
  },

  computed: {
    isMatchingMenuRow() {
      // matchingMenuSubmissionId will sometimes exist as a prop in the responses-table-row component but will
      // never exist as a prop in responses-table component. Check to see the prop even exists, returning false
      // if it doesn't, and returning the presence of the prop if it does exist.
      if (Object.keys(this.$props).includes('matchingMenuSubmissionId')) {
        return !!this.matchingMenuSubmissionId;
      }
      return false;
    },
  },

  methods: {
    stickyStyling(columnType) {
      // This will try to compute before the mounting is complete, as DOM elements refer to it for styling
      // Because it accesses $refs, which only exist after mounting is complete, we want to calculate it post-mount
      if (this.mounted) {
        let left = 0;
        const columnOrder = ['matchBreak', 'participant', 'role'];
        for (const column of columnOrder) {
          if (columnType === column) break;

          const cell = this.$refs[`${column}ColumnCell`];
          if (cell) left += cell.offsetWidth;
        }
        return {
          position: 'sticky',
          left: `${left}px`,
        };
      }
    },
  },

  mounted() {
    this.mounted = true;
  },
};
