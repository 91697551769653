export default {
  computed: {
    finishedSessionToast() {
      const { user } = this.finishedSession;
      const name = user.first_name || user.name || user.email;
      let text;
      if (name && this.finishedSession.user_is_finished) {
        text = `All of ${name}'s sessions are now complete so they will no longer appear on this page.`;
      } else {
        const sessionText = name ? `${name}'s session starting ${this.finishedSession.start_date}` : 'That session';
        text = `${sessionText} is now complete and will no longer appear on this page.`;
      }
      return `Resolved Flagged and Failed items. ${text}`;
    },
  },
};
