import useClientState from 'composables/client-state';

const useRecentlyViewedTags = () => {
  const { getLocalStorage, setLocalStorage } = useClientState();

  const getRecentlyViewedTagIds = () => {
    const recentlyViewedTagsJson = getLocalStorage('recentlyViewedTagIds');
    let recentlyViewedTagIds = [];
    if (recentlyViewedTagsJson) {
      try {
        recentlyViewedTagIds = JSON.parse(recentlyViewedTagsJson) || [];
      } catch (e) {
        // Do nothing, let recentlyViewedTags be an empty object.
      }
    }
    return recentlyViewedTagIds;
  };

  const setRecentlyViewedTagId = (tagId) => {
    let tagIds = getRecentlyViewedTagIds();
    tagIds = tagIds.filter(id => id !== tagId);
    tagIds.unshift(tagId);
    // Truncate to 4 ids max. Only 3 are shown, but storing 1 extra will fill in the 4th slot when a tag is archived.
    if (tagIds.length > 4) tagIds.length = 4;
    setLocalStorage('recentlyViewedTagIds', JSON.stringify(tagIds));
  };

  const setRecentlyViewedTagIds = (tagIds) => {
    setLocalStorage('recentlyViewedTagIds', JSON.stringify(tagIds));
  };

  return { getRecentlyViewedTagIds, setRecentlyViewedTagId, setRecentlyViewedTagIds };
};

export default useRecentlyViewedTags;
