import Random from 'lib/random';

export default {
  install: (app, options) => {
    app.mixin({
      methods: {
        uuid: Random.uuid,
      },
    });
  },
};
