export default {
  computed: {
    isIntro() {
      for (const type of this.introTypes) {
        if (this.event.event_type === type) {
          return true;
        }
      }
      return false;
    },

    introTypes() {
      return [
        'simple_pairing',
        'role_introducing',
        'user_introducing',
        'batch_pairing',
      ];
    },
  },
};
