import _ from 'lodash';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('onboardingEventsCalendar', {
      roles: state => state.roles,
    }),
  },

  methods: {
    roleColorClass(roleID) {
      const index = _.findIndex(this.roles, role => role.id === roleID);
      return `c${index % 8}`;
    },
  },
};
