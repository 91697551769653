export default {

  data: ->
    $.extend {
      currentPresetIdx: 0
      selectedPresets: []
      autoEnroll: null
      started: false
      done: false
      configuring: false
      confirming: false
      buddySelector: 'you'
      finished: false
      requestedPremium: false
      introModalIdx: 0
      templateName: "Your First Journey"
      workingTemplateName: ""
    }, Donut.Args

  computed: {

    # Event Rendering

    selectedEvents: ->
      if @selectedPresets.length > 0
        @events.filter (e) =>
          Donut.Array.find @selectedPresets, (p) -> p.id == e.preset_id
      else
        []

    eventDays: ->
      eventDays = {}
      for event in @selectedEvents
        eventDays[event.days_later] ||= []
        eventDays[event.days_later].push(event)
      eventDays

    minDay: ->
      daysWithEvents = Object.keys(@eventDays)
      if daysWithEvents.length > 0
        daysWithEvents.reduce (a,b) -> Math.min a,b
      else
        0

    maxDay: ->
      daysWithEvents = Object.keys(@eventDays)
      if daysWithEvents.length > 0
        daysWithEvents.reduce (a,b) -> Math.max a,b
      else
        4

    # State Management

    currentPreset: ->
      @presets[@currentPresetIdx] if @currentPresetIdx != null

    progressClass: ->
      if !@started
        'w-0p'
      else if @currentPresetIdx != null
        progress = @currentPresetIdx + 1
        progress += 1 if @confirming
        multiplier = if @premium then 25 else 20
        "w-#{progress*multiplier}p"
      else if @autoEnroll == null
        'w-80p'
      else
        'w-100p'

    choseNothing: ->
      @currentPresetIdx == null &&
        @started &&
        @selectedPresets.length == 0

    hasValidName: ->
      return @workingTemplateName.trim().length > 0

  }

  methods: {

    # Event Rendering

    weekLabel: (week) ->
      if week < -1
        "#{-week} Weeks Before"
      else if week == -1
        "1 Week Before"
      else if week == 0
        "First Week"
      else
        "Week #{week+1}"

    dayOfWeek: (idx) ->
      switch idx
        when 1 then 'M'
        when 2 then 'T'
        when 3 then 'W'
        when 4 then 'T'
        when 5 then 'F'

    highlightDay: (idx) ->
      if (@confirming || @configuring) && @currentPreset
        @eventDays[idx]?.find (e) => e.preset_id == @currentPreset.id
      else
        false

    shouldRenderWeek: (week) ->
      week >= Math.floor(@minDay/5) && week <= Math.floor(@maxDay/5)

    shouldRenderEvent: (event_id) ->
      Donut.Array.find @selectedEvents, (e) -> e.id == event_id

    # State Management

    imageURL: (path,version) ->
      "https://s3.amazonaws.com/assets.donut.ai/images/nucleo/#{path}.v#{version}.svg"

    confirm: ->
      if !@started
        @start()
      else if @currentPreset
        if @confirming
          @advance()
        else if @configuring
          window.mixpanel.track "Wizard - Configured Preset",
            { name: @currentPreset.name, buddy_selector: @buddy_selector }
          @configuring = false
          @confirming = true
        else
          window.mixpanel.track "Wizard - Chose Preset",
            { name: @currentPreset.name, action: 'selected' }
          @selectPreset()
      else if @autoEnroll == null
        window.mixpanel.track "Wizard - Chose Enrollment",
          { type: 'automatic' }
        @acceptAutoEnroll()
      else
        window.mixpanel.track "Wizard - Finished",
          { buddy_selector: @buddySelector, auto_enroll: @autoEnroll, selected_presets: @selectedPresets.map((p) -> p.name) }
        @finish()

    reject: ->
      if @currentPreset
        window.mixpanel.track "Wizard - Chose Preset",
          { name: @currentPreset.name, action: 'rejected' }
        @rejectPreset()
      else if @autoEnroll == null
        window.mixpanel.track "Wizard - Chose Enrollment",
          { type: 'manual' }
        @rejectAutoEnroll()

    back: ->
      if @configuring
        @configuring = false
      else if @confirming
        @confirming = false
      else
        if @currentPresetIdx == null
          if @autoEnroll == null || @premium
            @currentPresetIdx = @presets.length - 1
          @autoEnroll = null

        else
          @currentPresetIdx -= 1
      selectedIndex = @selectedPresets.indexOf(@currentPreset)
      @selectedPresets.splice(selectedIndex, 1) if selectedIndex >= 0

    startOver: ->
      @configuring = false
      @confirming = false
      @selectedPresets = []
      @currentPresetIdx = 0
      @autoEnroll = null
      @buddySelector = 'you'
      @requestedPremium = false

    requestPremium: ->
      @requestedPremium = true
      Intercom 'update',
        { 'requested_premium_at': Math.round(Date.now()/1000) }

    exit: ->
      window.location.href = '/onboarding/tags/overview'

    selectPreset: ->
      @selectedPresets.push(@currentPreset)

      scrollToEvent = Donut.Array.find @events, (e) => e.preset_id == @currentPreset.id
      delay 0, ->
        element = $("##{scrollToEvent.id}.event")
        $('body').stop().animate { scrollTop: (element.offset().top - 280) }, '600', 'swing'

      if @currentPreset.flags.indexOf('buddy_selecting') >= 0
        @configuring = true
      else
        @confirming = true

    rejectPreset: ->
      @advance()

    advance: ->
      if @currentPresetIdx < @presets.length - 1
        @currentPresetIdx += 1
      else
        @currentPresetIdx = null
        @autoEnroll = false if @premium
      @confirming = false

    acceptAutoEnroll: ->
      @autoEnroll = true

    rejectAutoEnroll: ->
      @autoEnroll = false

    start: ->
      @started = true
      @templateName = @workingTemplateName
      window.mixpanel.track "Wizard - Started"

    finish: ->
      @finished = true
      @$refs.form.submit()

    nextIntro: ->
      @introModalIdx += 1

    backIntro: ->
      @introModalIdx -= 1
  }
}
