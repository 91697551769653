import useEventsCalendarDays from 'composables/events-calendar/days';
import * as moment from 'moment';
import { useStore } from 'vuex';

// Calculate an array of weeks, each with 5 days, centered around a real date in the calendar and the days before and after
const useEventsCalendarDatedWeeks = () => {
  const { state, getters } = useStore();
  const { day30, day60, day90 } = useEventsCalendarDays(getters['onboardingEventsCalendar/startDay']);

  const dateFormatForId = 'MM/DD/YY';
  const dateFormatForLabel = 'ddd, MMM DD';
  const { events } = state.onboardingEventsCalendar;
  const earliestEvent = events[0];
  const latestEvent = events[events.length - 1];
  const firstDayOnCalendar = moment(earliestEvent.occurs_on, 'MM/DD/YY').day(1);
  const lastDayOnCalendar = moment(latestEvent.occurs_on, 'MM/DD/YY').day(5);

  const weeks = [];
  let currentDay = firstDayOnCalendar.clone();
  const startDate = getters['onboardingEventsCalendar/startDay'];
  while (currentDay.isBefore(lastDayOnCalendar)) {
    const days = [];
    for (let i = 0; i < 5; i += 1) {
      const day = {
        id: currentDay.format(dateFormatForId),
        label: currentDay.format(dateFormatForLabel),
        isStartDay: moment(startDate, 'MM/DD/YY').isSame(currentDay, 'day'),
        isToday: moment().isSame(currentDay, 'day'),
        isDay30: moment(startDate, 'MM/DD/YY').add(day30.value, 'd').isSame(currentDay, 'day'),
        isDay60: moment(startDate, 'MM/DD/YY').add(day60.value, 'd').isSame(currentDay, 'day'),
        isDay90: moment(startDate, 'MM/DD/YY').add(day90.value, 'd').isSame(currentDay, 'day'),
      };
      days.push(day);
      currentDay.add(1, 'd');
    }
    weeks.push({ days });
    currentDay = currentDay.add(2, 'd'); // The last `.add(1, 'd')` brought this up to Saturday, skip the weekend and get back to Monday
  }
  return { weeks };
};

export default useEventsCalendarDatedWeeks;
