import { mapState } from 'vuex';
import DonutModal from 'components/donut/modal.vue';

export default {
  components: {
    DonutModal,
  },

  computed: {
    featureTitleTextByPlanToUse() {
      return this.modal.data.featureTitleTextByPlan || { premium: this.modal.data.featureTitleText, paid_coffee: this.modal.data.featureTitleText };
    },

    selectedPlan() {
      return this.modal.data.defaultPremiumPlan ? 'premium' : 'paid_coffee';
    },

    selectedPlanName() {
      return this.modal.data.defaultPremiumPlan ? 'Premium' : 'Standard';
    },

    formAction() {
      return this.currentUser.paid_feature_access ? '/sign_up/change_plan' : '/sign_up';
    },

    featureInfo() {
      return this.featureTitleTextByPlanToUse[this.selectedPlan];
    },

    ...mapState([
      'currentUser',
      'modal',
    ]),
  },

  methods: {
    trackModalView(modalType) {
      window.mixpanel.track(`Upgrade Modal – Viewed ${modalType} Modal`, {
        path: window.location.pathname,
        source: this.modal.data.source,
      });
    },

    togglePlan() {
      this.$store.commit('updateModalData', { defaultPremiumPlan: !this.modal.data.defaultPremiumPlan });
    },
  },
};
