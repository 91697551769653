import { mapState } from 'vuex';

export default {
  props: {
    initTags: {
      type: Array,
      required: true,
    },
    initCoffeeBuildingBlocks: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapState('templateLibrary', {
      coffeeBuildingBlocks: state => state.coffeeBuildingBlocks,
    }),

    ...mapState([
      'modal',
    ]),
  },

  mounted() {
    this.$store.commit('templateLibrary/setTemplatesData', { tags: this.initTags, coffeeBuildingBlocks: this.initCoffeeBuildingBlocks });
    this.openTemplateModal();
  },

  methods: {
    openTemplateModal() {
      let buildingBlock;
      const previewVal = Donut.QueryParams.get('preview');
      const intPreviewVal = parseInt(previewVal, 10);
      if (intPreviewVal) {
        buildingBlock = this.coffeeBuildingBlocks.find(block => parseInt(block.id, 10) === intPreviewVal);
      } else if (previewVal) {
        buildingBlock = this.coffeeBuildingBlocks.find(block => !block.id && block.short_name.toLowerCase() === previewVal);
      }
      if (buildingBlock) {
        this.$store.dispatch('openBuildingBlockPreviewModal', { buildingBlock });
      }
    },
  },
};
