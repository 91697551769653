import useEventsCalendarDays from 'composables/events-calendar/days';
import { computed } from 'vue';
import { useStore } from 'vuex';

// Calculate an array of weeks, each with 5 days, based on a hypothetical start date of 0 and num days after or before
const useEventsCalendarNumberedWeeks = () => {
  const { state, getters } = useStore();

  const startDay = computed(() => getters['onboardingEventsCalendar/startDay']);
  const { day30, day60, day90 } = useEventsCalendarDays(startDay.value);
  const earliestWeek = Math.max(state.onboardingEventsCalendar.firstWeek, getters['onboardingEventsCalendar/firstWeekMin']);
  const latestWeek = Math.max(state.onboardingEventsCalendar.lastWeek, getters['onboardingEventsCalendar/lastWeekMin']);
  const weekNums = new Array(earliestWeek + latestWeek).fill().map((_val, idx) => idx - earliestWeek);
  const weeks = weekNums.map((weekNum) => {
    const days = Array(5).fill().map((_val, idx) => {
      const currentDay = (weekNum * 5) + idx - startDay.value;
      const startOffset = 4 - startDay.value;
      const weekendsPassed = Math.ceil((currentDay - startOffset) / 5);
      const calendarDay = currentDay + (2 * weekendsPassed);
      const weekdayLabels = ['M', 'T', 'W', 'Th', 'F'];
      return {
        id: currentDay,
        isStartDay: calendarDay === 0,
        isDay30: calendarDay === day30.value,
        isDay60: calendarDay === day60.value,
        isDay90: calendarDay === day90.value,
        label: weekdayLabels[idx],
      };
    });
    return { num: weekNum, days };
  });
  return { weeks };
};

export default useEventsCalendarNumberedWeeks;
