import Routes from 'lib/routes.js';

export default {
  templates() {
    return {
      label: 'Journeys',
      url: Routes.templates(),
    };
  },

  channelPrograms() {
    return {
      label: 'All Channels',
      url: Routes.channelPrograms(),
    };
  },
};
