import { mapState } from 'vuex';

export default {
  data() {
    return {
      cancelURL: '/all_channels',
    };
  },

  computed: {
    valid() {
      return this.isEmptyDeep(this.errors);
    },

    ...mapState([
      'errors',
    ]),
  },

  mounted() {
    Donut.Event.Bus.register(Donut.Event.VALIDATE_FORM, this.onValidate);

    this.$nextTick(() => {
      const { scrollElementID } = this;

      if (scrollElementID != null) {
        const $element = $(scrollElementID);
        if ($element.length > 0) {
          $('body').stop().animate({ scrollTop: ($element.offset().top - 50) }, '600', 'swing');
          $element.addClass('form-highlighted');
        }
      }
    });
  },

  beforeUnmount() {
    this.storeError(null);
    Donut.Event.Bus.deregister(Donut.Event.VALIDATE_FORM, this.onValidate);
  },

  methods: {
    submit(type) {
      // TODO: how should we submit?
      this.$refs.form.submit(type);
    },

    onValidate() {
      // Give all of our fields time to react to the validation
      this.$nextTick(() => {
        const $error = $('.has-error');
        if ($error.length > 0) {
          $('body').stop().animate({ scrollTop: ($error.offset().top - 50) }, '600', 'swing');
        }
      });
    },
  },
};
