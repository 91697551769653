import { mapState } from 'vuex';

export default {
  computed: {
    isSelected() {
      return this.currentPlan === this.planDetails.plan;
    },

    isSignUpPage() {
      return this.page === 'sign_up';
    },

    isBillingPage() {
      return this.page === 'billing';
    },

    signUpSource() {
      return isBillingPage() ? 'billing' : 'signUp';
    },

    ...mapState([
      'page',
      'currentPlan',
    ]),
  },
};
