export default {
  data() {
    return {
      statusData: {
        done: {
          key: 'done',
          label: 'Done',
          icon: 'done',
          description: 'Completed Polls, Tasks, and Intros, Emails that have been opened, and Messages that were sent.',
        },
        pending: {
          key: 'pending',
          label: 'Pending',
          icon: 'drafts',
          description: 'Sent Polls, Tasks, and Intros that have not been Flagged, and Emails that have been sent, but not opened.',
        },
        resolved: {
          key: 'resolved',
          label: 'Resolved',
          icon: 'done',
          description: 'Flagged or Failed messages that an admin marked as resolved.',
        },
        flagged: {
          key: 'flagged',
          label: 'Flagged',
          icon: 'warning',
          description: 'Polls, Tasks, and Intros that the recipient has dismissed or ignored for more than 3 business days.',
        },
        failed: {
          key: 'failed',
          label: 'Failed',
          icon: 'warning',
          description: 'Messages that Donut couldn’t send due to unassigned roles, timing issues, or an invalid email address.',
        },
        scheduled: {
          key: 'scheduled',
          label: 'Scheduled',
          icon: 'today',
          description: 'Messages that haven’t been sent yet.',
        },
      },

      stateMap: {
        done: 'done', omitted: 'done', resolved: 'resolved', waiting: 'pending', behind: 'flagged', abandoned: 'flagged', failed: 'failed', unsent: 'scheduled',
      },

      stateGroupings: {
        done: ['done', 'omitted'], waiting: ['waiting'], flagged: ['behind', 'abandoned'], failed: ['failed'], resolved: ['resolved'], unsent: ['unsent'],
      },
    };
  },
};
