import { mapState } from 'vuex';

export default {
  computed: {
    paidFeatureAccess() {
      return this.watercooler.service_account.paidFeatureAccess;
    },

    ...mapState([
      'watercooler',
      'packs',
    ]),
  },

  methods: {
    openLibrary() {
      this.$store.commit('drawer/updateDrawer', {
        visible: true,
        title: 'Watercooler Packs',
        data: {
          description: `Subscribe to packs to get their topics in your Watercooler.
            As Donut and our partners add new topics to packs, those topics
            will automatically be added to your Watercooler. You can rearrange
            and deactivate specific topics once a pack has been added.`,
          addNew: {
            buttonText: 'Create a Custom Pack',
            subcomponent: 'watercoolers-modals-edit-pack',
            upgradeRequired: {
              featureTitleText: 'create your own Watercooler packs',
              source: 'watercooler-packs-add-new',
              featureInfo: {
                programKey: 'watercooler',
                feature: 'watercooler-custom',
              },
            },
          },
        },
      });
      window.mixpanel.track('Watercooler Packs - Opened Packs Library', { watercoolerId: this.watercooler.id });
    },

    verifyCanChangePackSubscriptions(id, isSubscribing, isConfirmingUnsubscribe = false) {
      // No updating Subscriptions on a free plan
      if (!this.paidFeatureAccess) {
        this.openChannelProgramsPlanUpgradeModal();
        return;
      }
      if (isConfirmingUnsubscribe) {
        return this.confirmUnsubscibeFromPack(id);
      }
      this.updatePackSubscription(id, isSubscribing);
    },

    openChannelProgramsPlanUpgradeModal() {
      this.$store.dispatch('openChannelProgramsPlanUpgradeModalForWatercoolerPrompts');
    },

    confirmUnsubscibeFromPack(packId) {
      this.$store.commit('updateModal', {
        visible: true,
        subcomponent: 'watercoolers-modals-confirm-unsubscribe-from-pack',
        data: { packId },
      });
    },

    updatePackSubscription(id, isSubscribing) {
      const action = `${isSubscribing ? 'create' : 'destroy'}WatercoolerPacksWatercooler`;
      this.$store.dispatch(action, { pack_id: id });
      window.mixpanel.track('Watercooler Packs - Update Pack Subscription', {
        isSubscribing,
        packId: id,
        watercoolerId: this.watercooler.id,
      });
    },

    promptIsDisabled(prompt) {
      // If it's a 1) new prompt 2a) a custom prompt or 2b) a prompt that isn't in the subscribed-to Default pack
      // AND it's on a free account, you can't interact with it
      return (!prompt || !prompt.in_subscribed_free_pack) && !this.paidFeatureAccess;
    },
  },
};
