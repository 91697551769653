import { mapState } from 'vuex';
import Logos from '../logos.vue';

export default {
  extends: Logos,

  props: {
    large: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    slackLink() {
      switch (this.page) {
        case 'billing':
          return 'http://bit.ly/2Y0If71';
        case 'onboarding':
          return 'http://bit.ly/2CmV42h';
        case 'sign_up':
          return 'http://bit.ly/2FaJHw9';
        default:
          return 'https://slackhq.com/how-to-gently-onboard-new-hires-using-slack';
      }
    },

    slackHeight() {
      if (this.large) {
        return 28;
      }
      return 18;
    },

    slackLogo() {
      return {
        image: 'logos/slack',
        link: this.slackLink,
        height: this.slackHeight,
      };
    },

    automatticLink() {
      switch (this.page) {
        case 'billing':
          return 'http://bit.ly/2UCNec3';
        case 'onboarding':
          return 'http://bit.ly/2CiDFHW';
        case 'sign_up':
          return 'http://bit.ly/2TxYUjR';
        default:
          return 'https://www.donut.com/blog/impactful-efficient-onboarding-at-automattic/';
      }
    },

    automatticHeight() {
      if (this.large) {
        return 15;
      }
      return 10;
    },

    automatticLogo() {
      return {
        image: 'logos/automattic',
        link: this.automatticLink,
        height: this.automatticHeight,
      };
    },

    cultureAmpLink() {
      switch (this.page) {
        case 'billing':
          return 'http://bit.ly/2UHVh7u';
        case 'onboarding':
          return 'http://bit.ly/2F4jnm1';
        case 'sign_up':
          return 'http://bit.ly/2VV6tO3';
        default:
          return 'https://blog.cultureamp.com/creating-a-culture-first-onboarding-programk';
      }
    },

    cultureAmpHeight() {
      if (this.large) {
        return 28;
      }
      return 18;
    },

    cultureAmpLogo() {
      return {
        image: 'logos/cultureamp',
        link: this.cultureAmpLink,
        height: this.cultureAmpHeight,
      };
    },

    logos() {
      return [
        this.slackLogo,
        this.automatticLogo,
        this.cultureAmpLogo,
      ];
    },

    ...mapState([
      'page',
    ]),
  },
};
