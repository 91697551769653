// This method has been ported to the day-styles composable for composition API components
// We should eventually deprecate this page
export default {
  methods: {
    workDaysLabelForDay(day) {
      if (day < 0) {
        return `${-day} Work Days Before`;
      } if (day === 0) {
        return 'Start Date';
      }
      return `Work Day ${day + 1}`;
    },
  },
};
