import * as moment from 'moment';

export default {
  methods: {
    calculateSendDate(promptIndex, watercooler) {
      const indices = this.weekdayIndices(watercooler);
      const sendDate = moment(watercooler.next_occurrence_on, 'ddd, MMM D, YYYY');

      for (let i = 0; i < promptIndex; i += 1) {
        if (watercooler.period) {
          sendDate.add(watercooler.period, 'day');
        } else if (indices.length) {
          sendDate.add(1, 'day');
          while (!indices.includes(sendDate.weekday())) {
            sendDate.add(1, 'day');
          }
        }
      }
      return sendDate.format('ddd, MMM D');
    },

    weekdayIndices(watercooler) {
      return watercooler.weekdays.map((wd) => {
        switch (wd) {
          case 'sun':
            return 0;
          case 'mon':
            return 1;
          case 'tue':
            return 2;
          case 'wed':
            return 3;
          case 'thu':
            return 4;
          case 'fri':
            return 5;
          case 'sat':
            return 6;
          default:
            return null;
        }
      });
    },
  },
};
