import { computed } from 'vue';

const useEventsCalendarDays = (startDay) => {
  const day30 = computed(() => {
    if (startDay === 4) return 31;
    return 29;
  });

  const day60 = computed(() => {
    if (startDay === 3) return 60;
    if (startDay === 2) return 61;
    return 59;
  });

  const day90 = computed(() => {
    if (startDay === 1) return 90;
    if (startDay === 0) return 91;
    return 89;
  });

  return { day30, day60, day90 };
};

export default useEventsCalendarDays;
