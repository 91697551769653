import { mapState } from 'vuex';

export default {
  computed: {
    planDetails() {
      return {
        plan: 'free',
        name: 'Free',
        price: this.currentUser.plan_pricing_data.free.monthly.base,
        image: 'graphics/plans-free',
        pairingItems: this.pairingItems,
        header: { pairing: 'FREE FOREVER!', onboarding: null },
        showPricingCalc: false,
      };
    },

    pairingItems() {
      const details = [
        'Send up to 3 messages per Journey',
        `Introduce up to ${this.freePlanLimits.pairing_users} people per round from 1 Donut Intros Channel to meet and get to know each other`,
        `Spark discussion with up to ${this.freePlanLimits.watercooler_topics} in-channel Donut Watercooler topics`,
        'Invite new hires to join your Donut channels',
        // We don't actually enforce this, we're just seeing how people will react. 🤫
        "Celebrate up to 24 people's birthdays and work anniversaries per year in 1 Donut Celebrations Channel",
        'Use Donut\'s full library of Journeys, including onboarding, mentorship, training and events best practices',
      ];
      if (this.currentUser.hris_free_promo) {
        details.push('Connect your HRIS to automatically add new hires and assign Journeys');
      }
      return details;
    },

    ...mapState([
      'currentUser',
      'freePlanLimits',
    ]),
  },
};
