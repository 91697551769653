import * as moment from 'moment';

const emojiRegex = /:[a-zA-Z0-9-_+]+:(:skin-tone-[2-6]:)?/g;

export default {

  install: (app, options) => {
    app.mixin({
      methods: {
        presenceValidator(label = 'This', count = 1) {
          return (value) => {
            if (value == null || (typeof (value) === 'string' && value.trim() === '')) return `${label} is required`;
            if (value.length != null) {
              if (value.length < count) {
                if (count <= 1) return `${label} is required`;
                return `You must select at least ${count} ${label}.`;
              }
            }
          };
        },

        uniquenessValidator(label = 'This') {
          return (value, forComparison) => {
            let err = null;
            forComparison.forEach((comparableValue) => {
              if (comparableValue.name.toLowerCase().trim() === value.toLowerCase().trim()) {
                err = `${label} must be unique`;
              }
            });
            return err;
          };
        },

        integerRangeValidator(min, max, label = 'This') {
          return (value) => {
            const intVal = parseInt(value, 10);
            const valid = parseFloat(value) === intVal && intVal >= min && intVal <= max;
            if (!valid) {
              return `${label} must be a whole number between ${min} and ${max}`;
            }
          };
        },

        textWidthValidator(limit, label = 'Text') {
          return (value) => {
            if (this.estimatedTextWidth(value) > limit || value.length > 75) {
              return `${label} is too long`;
            }
          };
        },

        characterCountValidator(limit, label = 'Text', compressEmojis = false) {
          return (value) => {
            let charCount;
            if (compressEmojis) {
              const matches = value.match(emojiRegex);
              const emojiCount = (matches && matches.length) || 0;
              const emojilessText = value.replace(emojiRegex, '');
              charCount = emojilessText.length + emojiCount;
            } else {
              charCount = value.length;
            }
            if (charCount > limit) {
              return `${label} is too long`;
            }
          };
        },

        availabilityValidator(cache, item, label = 'This') {
          return (value) => {
            const potentiallyValid = (cache[item] === undefined) || (cache[item] === 'searching');
            const definitelyValid = cache[item] === 'valid';
            if (!(potentiallyValid || definitelyValid)) {
              return `${label} already exists`;
            }
          };
        },

        slackChannelNameRestrictionValidator() {
          return (value) => {
            const regex = /^[a-z0-9-_]+$/;
            if (value.length > 80) {
              return 'Channel names can\'t be longer than 80 characters.';
            } if (value.toLowerCase() !== value) {
              return 'Channel names can\'t contain uppercase letters.';
            } if (!regex.test(value)) {
              return 'Channel names can\'t contain spaces, periods, or most punctuation.';
            }
          };
        },

        futureValidator(label = 'The date', manualOverride = false) {
          return (value) => {
            if (manualOverride || !this.atOccurrenceTime(value).isAfter(moment())) {
              return `${label} must be in the future`;
            }
          };
        },

        // Journeys

        messageRoleReferenceValidityValidator: (value) => {
          const results = Donut.Formatter.hasValidRoleReferences(value);
          if (!results[1]) {
            return 'You are referencing a role that does not exist';
          }
        },

        // Helpers

        estimatedTextWidth(text) {
          const matches = text.match(emojiRegex);
          const emojiCount = (matches && matches.length) || 0;
          const emojilessText = text.replace(emojiRegex, '');

          let textLength = 0;
          for (let i = 0; i < emojilessText.length; i += 1) {
            const char = emojilessText.charAt(i);
            if (['l', 'i', 'I', 'j', '|'].includes(char)) {
              textLength += 1;
            } else {
              textLength += 2;
            }
          }
          textLength += (emojiCount * 3);
          return textLength;
        },

        isEmptyDeep(object) {
          for (const key in object) {
            const value = object[key];
            if (typeof value === 'object') {
              if (!this.isEmptyDeep(value)) {
                return false;
              }
            } else if (value !== null && value !== undefined) {
              return false;
            }
          }
          return true;
        },
      },
    });
  },

};
