export default {
  props: {
    session: {
      type: Object,
      required: true,
    },
  },

  computed: {
    hasResolvableEvents() {
      for (const tag of this.session.tags) {
        for (const state in tag.event_state_counts) {
          if (state === 'failed' || state === 'abandoned' || state === 'behind') {
            if (tag.event_state_counts[state] > 0) {
              return true;
            }
          }
        }
      }
      return false;
    },
  },
};
