import { useStore } from 'vuex';
import { ref, computed } from 'vue';

const useEventInteractivity = (event) => {
  const { state } = useStore();

  const isLoading = ref(false);

  const allowInteraction = computed(() => (
    state.onboardingSiteDisplay.isSiteOfCurrentUser && event.delivered
  ));

  const onComplete = () => {
    isLoading.value = false;
  };

  // Returns null if the user can interact with the event.
  const buttonTooltip = computed(() => {
    if (state.onboardingSiteDisplay.isPreview) return 'This is just a preview, you cannot interact with it';
    if (!state.onboardingSiteDisplay.isSiteOfCurrentUser) return 'Only the owner of this Hub can interact with it';
    if (!event.delivered) return "You can't interact with this since it hasn't been sent yet";
  });

  const interactivityClasses = computed(() => ({
    'o-50': isLoading.value,
    'c-not-allowed': !allowInteraction.value,
  }));

  return { isLoading, allowInteraction, onComplete, buttonTooltip, interactivityClasses };
};

export default useEventInteractivity;
