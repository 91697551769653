import _ from 'lodash';

export default {
  methods: {
    // Message preamble

    names(config) {
      const nameList = [1, 2, 3, 4, 5, 6, 7, 8].map(n => `participant ${n}`);
      if (this.config.pairing_type === 'lottery') {
        ['lottery_user', 'second_lottery_user'].forEach((field, i) => {
          if (this.config[field]) {
            nameList[i] = this.config[field].mention_name.slice(1);
          }
        });
      }
      return nameList;
    },

    greetingText(config) {
      const wave = Donut.Formatter.formatTextForSlack(':wave:');
      const groupSize = config.group_size || 2;
      const isCrossGroup = config.pairing_type === 'cross_group_pairing';
      let groups = [];
      if (isCrossGroup) {
        const options = config.category_options;
        groups = options.length ? options.map(o => o.name) : ['Group 1', 'Group 2'];
      }
      const formattedNames = this.names(config).slice(0, groupSize).map((name, i) => {
        let categoryOptionName = '';
        if (isCrossGroup) {
          categoryOptionName = ` (${groups[i % groups.length]})`;
        }
        return `<highlight class='variable'>@${name}</highlight>${categoryOptionName}`;
      }).join(', ');
      return `${wave}${formattedNames}`;
    },

    // Message body

    freqString(period, weekdays) {
      if (period === 0) {
        return `every ${this.getWeekdayString(weekdays)}`;
      }
      const weeks = period / 7;
      if (weeks === 1) {
        return 'every week';
      }
      return `every ${weeks} weeks`;
    },

    getWeekdayString(weekdays) {
      if (weekdays.length === 5) {
        return 'weekday';
      }
      const sortedWeekdays = ['mon', 'tue', 'wed', 'thu', 'fri'].filter(wday => weekdays.includes(wday));
      return this.toSentence(sortedWeekdays.map(wday => _.capitalize(wday)));
    },

    channelReference(channelUid) {
      const uid = channelUid || 'yourchannel';
      return `<#${uid}>`;
    },

    replacedMessage(message, args) {
      const channelReference = this.channelReference(args.channelUid);
      let replacedMessage = message.replace(/\[channel\]/, channelReference);
      if (args.period || args.weekdays) {
        const freqString = this.freqString(args.period, args.weekdays);
        replacedMessage = replacedMessage.replace(/\[frequency\]/, freqString);
      }
      return replacedMessage;
    },

    formattedReplacedMessage(message, messageArgs, formattingArgs) {
      const replacedMessage = this.replacedMessage(message, messageArgs);
      return Donut.Formatter.formatTextForSlack(replacedMessage, formattingArgs);
    },

    // Message accessories

    videoConferencingInCalEventText(config) {
      if (config.video_platform === 'zoom') {
        return 'A Zoom link will be included in the invite.';
      } if (config.video_platform === 'meet_and_teams') {
        const platforms = [];
        if (!config.disabled_gcal) platforms.push('Google Meet');
        if (!config.disabled_outlook) platforms.push('Microsoft Teams');
        if (platforms.length > 0) return `A ${platforms.join(' / ')} link will be included in the invite.`;
      }
    },
  },
};
