export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    invert: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      proxyValue: false,
    };
  },

  created() {
    this.updateProxyValue();
  },

  watch: {
    modelValue() {
      this.updateProxyValue();
    },

    proxyValue() {
      const { invert, proxyValue } = this;
      const emitValue = invert ? !proxyValue : proxyValue;
      this.$emit('update:modelValue', emitValue);
    },
  },

  methods: {
    updateProxyValue() {
      const { invert, modelValue } = this;

      if (invert) this.proxyValue = !modelValue;
      else this.proxyValue = modelValue;
    },
  },
};
