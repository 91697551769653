import Event from './_event';

export default {
  mixins: [
    Event,
  ],

  props: {
    includeDay: {
      type: Boolean,
      default: false,
    },
    channel: {
      type: Object,
      default: null,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    previewEvent() {
      const event = {
        event_type: this.event.event_type,
        days_later: this.event.days_later,
        minute_in_day: this.event.minute_in_day,
        message: this.event.message,
        channel_send: this.event.channel_send, // TODO: seems unused in the preview
        channel: this.channel,
        channel_configuration: this.event.channel_configuration,
        recipient_channel_configuration: this.event.recipient_channel_configuration,
        requirements: this.event.requirements,
        parent_event_id: this.event.parent_event_id,
        description: this.event.description,
      };

      if (this.event.event_type !== 'batch_channel_creating') {
        event.role = { name: this.role };
      }

      // eslint-disable-next-line default-case
      switch (this.event.event_type) {
      case 'tasking':
        event.tasks = this.event.tasks;
        break;
      case 'polling':
        event.polls = [{
          query: this.event.poll_query,
          options: this.event.poll_options,
          collect_feedback: this.event.collect_feedback,
        }];
        break;
      case 'role_introducing':
        event.intro = {
          to: {
            name: this.secondRole,
          },
        };
        break;
      case 'emailing':
        event.email = {
          from: {
            avatar: null,
            email: null,
            name: null,
          },
          subject: this.event.email_subject,
        };
        break;
      case 'group_messaging':
        event.group_messaging = {
          to: this.event.group_participant_roles || [],
        };
        break;
      }
      return event;
    },
  },
};
