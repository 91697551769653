import { computed } from 'vue';

const useEventsCalendarWeekStyles = (week) => {
  const weekLabel = computed(() => {
    if (week < 0) {
      const weeks = week === -1 ? 'Week' : 'Weeks';
      return `${-week} ${weeks} Before Start`;
    } if (week === 0) {
      return 'First Week';
    }
    return `Week ${week + 1}`;
  });

  return { weekLabel };
};

export default useEventsCalendarWeekStyles;
