export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },

  computed: {
    formattedPollQuery() {
      return Donut.Formatter.formatTextForSlack(this.selectedPoll.query);
    },
  },
};
