import { mapState } from 'vuex';

export default {
  computed: {
    programs() {
      return [
        {
          key: 'intros',
          classes: 'bg-yellow-light',
          icon: this.productMetadata.CoffeeConfiguration.icon_svg,
          textColor: this.productMetadata.CoffeeConfiguration.color_class,
          features: [
            {
              name: 'intros-unlimited-channels',
              header: 'Unlimited Intros channels and participants',
              description: 'Create as many Intros channels and match as many people as you want.',
              imgPath: 'upgrade_modals/unlimited_participants',
            },
            {
              name: 'intros-template-access',
              header: 'Access all of Donut\'s templates',
              description: 'Don\'t wanna start from scratch? Explore Donut\'s variety of program templates for faster set up.',
              imgPath: 'upgrade_modals/template_access',
            },
            {
              name: 'intros-advanced-settings',
              header: 'Advanced customizations and settings',
              description: 'Customize group sizes, Intro type, Intro message, conversation starters, and access other advanced settings.',
              imgPath: 'upgrade_modals/advanced_settings',
            },
            {
              name: 'intros-advanced-reporting',
              header: 'More detailed reporting',
              description: 'See who\'s been matched with who, how many people met, user feedback, and other advanced reporting.',
              imgPath: 'upgrade_modals/advanced_reporting',
            },
          ],
        },
        {
          key: 'watercooler',
          classes: 'bg-lighter-purple',
          icon: this.productMetadata.Watercooler.icon_svg,
          textColor: this.productMetadata.Watercooler.color_class,
          features: [
            {
              name: 'watercooler-unlimited-channels',
              header: 'Unlimited Watercooler channels',
              description: 'Create as many Watercooler channels as you want for different ways of connecting.',
              imgPath: 'upgrade_modals/unlimited_watercooler',
            },
            {
              name: 'watercooler-packs',
              header: 'Access Donut\'s collection of Watercooler topic packs',
              description: 'Keep your Watercooler conversations flowing with Donut\'s topic packs.',
              imgPath: 'upgrade_modals/watercooler_packs',
            },
            {
              name: 'watercooler-custom',
              header: 'Create custom Watercooler<br />topics and packs',
              description: 'Make your Watercooler conversations as distinctive as your team.',
              imgPath: 'upgrade_modals/custom_packs',
            },
            {
              name: 'watercooler-enterprise-grid',
              header: 'Enterprise grid access',
              description: 'Use Watercooler in Enterprise Grid multi-workspace channels.',
              imgPath: 'upgrade_modals/enterprise_grid',
            },
          ],
        },
        {
          key: 'celebrations',
          classes: 'bg-green-light',
          icon: this.productMetadata.CelebrationProgram.icon_svg,
          textColor: this.productMetadata.CelebrationProgram.color_class,
          features: [
            {
              name: 'celebrations-unlimited-channels',
              header: 'Unlimited Celebrations channels',
              description: 'Create multiple programs for all the things you have to celebrate.',
              imgPath: 'upgrade_modals/unlimited_celebrations_channels',
            },
            {
              name: 'celebrations-unlimited-celebrations',
              header: 'Unlimited Celebrations participants',
              description: 'Celebrate as many people\'s birthdays and work anniversaries as you want.',
              imgPath: 'upgrade_modals/unlimited_celebrations',
            },
            {
              name: 'celebrations-custom',
              header: 'Create Custom Celebrations',
              description: 'Celebrate more than just birthdays and work anniversaries by adding your own custom holidays.',
              imgPath: 'upgrade_modals/custom_celebrations',
            },
            {
              name: 'celebrations-group',
              header: 'Customize messages and cadence',
              description: 'Celebrate exactly how you want,<br />when you want.',
              imgPath: 'upgrade_modals/group_celebrations',
            },
          ],
        },
      ];
    },

    ...mapState([
      'productMetadata',
    ]),
  },
};
