import Routes from 'lib/routes.js';

export default {
  buildingBlocks() {
    return {
      label: 'Building Blocks',
      url: Routes.buildingBlocks(),
    };
  },
};
