export default {
  methods: {
    assignment(info) {
      if (info.channel_recipient) {
        return { role: 'Slack Channel', name: info.channel_recipient.name, avatar: 'slack_icon', ids: { channel_id: info.channel_recipient.id } };
      } if (info.user) {
        return { role: 'Specific Person', name: info.user.name, avatar: info.user.avatar, ids: { user_id: info.user.id } };
      } if (info.role) {
        if (info.role.user) {
          return { role: info.role.name, name: info.role.user.name, avatar: info.role.user.avatar, ids: { role_id: info.role.id, user_id: info.role.user.id } };
        }
        return { role: info.role.name, name: null, avatar: null, ids: { role_id: info.role.id } };
      }
    },

    isExactSameRecipient(first, second) {
      return first.channel_id === second.channel_id && first.user_id === second.user_id && first.role_id === second.role_id;
    },

    isSameUserInDifferentRole(first, second) {
      return first.user_id && first.user_id === second.user_id && first.role_id !== second.role_id;
    },
  },
};
