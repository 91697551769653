import { mapState } from 'vuex';

export default {
  install: (app, options) => {
    app.mixin({
      methods: {
        noOp() {},
      },
    });
  },
};
