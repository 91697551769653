import { mapState } from 'vuex';

export default {
  // We should consider deriving more of these (every one except previewMessage) from the backend to better maintain consistency. I didn't because these messages
  // are old and comprised of multiple methods that assemble attachments, not just strings. Would probably be helpful in the future though.
  computed: {
    previewMessage() {
      const selection = this.coffeeConfiguration.default_intro_type;
      if (selection === 'custom') {
        return this.coffeeConfiguration.intro_message;
      } if (selection) {
        const intro = this.defaultIntros.find(i => i.name === selection);
        return intro.message;
      }
      return this.defaultIntros[0].message;
    },

    selfiePreviewMessageFirst() {
      return ':sparkles::selfie::sparkles: The *Donut selfie contest* is complete! \n'
             + "Congratulations to our winners <highlight class='variable'>@jeff</highlight>, "
             + "<highlight class='variable'>@shipper</highlight>, "
             + "<highlight class='variable'>@maia</highlight>, and "
             + "<highlight class='variable'>@laura</highlight> who got 3 votes on their selfie :clap::clap:";
    },

    selfiePreviewMessageSecond() {
      return "If you didn't win this time, your next shot at victory begins now! "
             + "A new contest has just begun so don't forget to post selfies from your "
             + 'upcoming meetings into this channel and vote on your favorites.';
    },

    selfiePreviewButton() {
      return Donut.Formatter.formatTextForSlack(':information_source: More info');
    },

    ...mapState([
      'coffeeConfiguration',
      'defaultIntros',
    ]),
  },
};
