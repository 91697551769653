import { mapState } from 'vuex';
import Logos from './logos.vue';

export default {
  extends: Logos,

  computed: {
    hotelTonightLink() {
      switch (this.page) {
        case 'billing':
          return 'http://bit.ly/2UCXYHl';
        case 'onboarding':
          return 'http://bit.ly/2HBj19i';
        case 'sign_up':
          return 'http://bit.ly/2HjvajS';
        default:
          return 'https://www.linkedin.com/pulse/improve-your-team-culture-weeks-sam-shank/';
      }
    },

    hotelTonightLogo() {
      return {
        image: 'logos/hoteltonight',
        link: this.hotelTonightLink,
        height: 32,
      };
    },

    ibmLink() {
      switch (this.page) {
        case 'billing':
          return 'http://bit.ly/2u6b0S2';
        case 'onboarding':
          return 'http://bit.ly/2UBS09C';
        case 'sign_up':
          return 'http://bit.ly/2CiAAI5';
        default:
          return 'https://medium.com/letters-from-slash-hyphen/how-ibm-is-using-slack-to-persuade-software-engineers-to-socialize-72731a642319';
      }
    },

    ibmLogo() {
      return {
        image: 'logos/ibm',
        version: 1,
        link: this.ibmLink,
        height: 32,
      };
    },

    invisionLink() {
      switch (this.page) {
        case 'billing':
          return 'http://bit.ly/2UEcZbT';
        case 'onboarding':
          return 'http://bit.ly/2ChF5ST';
        case 'sign_up':
          return 'http://bit.ly/2Uy3uuB';
        default:
          return 'https://www.invisionapp.com/inside-design/how-invision-uses-slack-power-culture-communication/';
      }
    },

    invisionLogo() {
      return {
        image: 'logos/invision',
        version: 1,
        link: this.invisionLink,
        height: 32,
      };
    },

    logos() {
      return [
        this.hotelTonightLogo,
        this.ibmLogo,
        this.invisionLogo,
      ];
    },

    ...mapState([
      'page',
    ]),
  },
};
