import * as moment from 'moment';

export default {
  // There is a lot of time/date calculations and auth stuff required for fixed meeting time.
  // This file helps clear `calendar-scheduling.vue` of some of those calculations.
  // Everything in here requires a `coffeeConfiguration` object in the vuex state.
  data() {
    return {
      momentFormat: 'ddd, MMM D, YYYY',
      datepickerFormat: 'M/D/YYYY',
      keyContent: `<div class='js-datepicker-key datepicker-key p-v12 p-h20 m-t5'>
        <p class='t'><span class='key outline br-2 m-r5'></span> = Intro Date</p>
        <p class='t m-t8'><span class='key solid br-2 m-r5'></span> = Meeting Date</p>
        <p class='t m-t8'><span class='key outline solid br-2 m-r5'></span> = Intro &amp; Meeting Date</p>
      </div>`,
      hasClickedAuthLink: false,
    };
  },

  computed: {
    schedulingText() {
      // Based on frequency_description in recurring_scheduling.rb, but needs to be dynamically adjusted based on vuex state.
      const { period, weekdays, occurrence_hour, occurrence_minute } = this.coffeeConfiguration; // eslint-disable-line camelcase
      let frequencyText;
      if (period === 0) {
        if (weekdays.length === 5) {
          frequencyText = 'weekday';
        } else {
          const weekdayMap = { mon: 'Monday', tue: 'Tuesday', wed: 'Wednesday', thu: 'Thursday', fri: 'Friday' };
          const weekdaysMapped = weekdays.sort((a, b) => Object.keys(weekdayMap).indexOf(a) - Object.keys(weekdayMap).indexOf(b)).map(d => weekdayMap[d]);
          frequencyText = `${weekdaysMapped.slice(0, weekdaysMapped.length - 1).join(', ')} and ${weekdaysMapped.slice(-1)}`;
        }
      } else {
        const numWeeks = period / 7;
        frequencyText = numWeeks === 1 ? 'week' : `${numWeeks} weeks`;
      }

      let time;
      const min = occurrence_minute < 10 ? `0${occurrence_minute}` : occurrence_minute; // eslint-disable-line camelcase
      if (occurrence_hour === 0) { // eslint-disable-line camelcase
        time = `12:${min}am`;
      } else if (occurrence_hour > 0 && occurrence_hour < 12) { // eslint-disable-line camelcase
        time = `${occurrence_hour}:${min}am`; // eslint-disable-line camelcase
      } else if (occurrence_hour === 12) { // eslint-disable-line camelcase
        time = `12:${min}pm`;
      } else {
        time = `${occurrence_hour - 12}:${min}pm`; // eslint-disable-line camelcase
      }
      return `every ${frequencyText} at ${time}.`;
    },

    nextIntroOnMoment() {
      return moment(this.coffeeConfiguration.next_occurrence_on, this.momentFormat);
    },

    nextMeetingOnMoment() {
      return moment(this.coffeeConfiguration.next_fixed_meeting_on, this.momentFormat);
    },

    startDate() {
      return this.nextIntroOnMoment.clone().format('L');
    },

    endDate() {
      if (this.coffeeConfiguration.period === 0) {
        return this.nextMeetingOnMoment.clone().format('L');
      }
      return this.nextIntroOnMoment.clone().add(this.coffeeConfiguration.period - 1, 'd').format('L');
    },

    chosenWeekdays() {
      return this.coffeeConfiguration.weekdays.map((wd) => {
        switch (wd) {
          case 'sun':
            return 0;
          case 'mon':
            return 1;
          case 'tue':
            return 2;
          case 'wed':
            return 3;
          case 'thu':
            return 4;
          case 'fri':
            return 5;
          case 'sat':
            return 6;
          default:
            return null;
        }
      });
    },

    multiDayMeetingDates() {
      const meetings = [];
      const start = moment(this.startDate, 'L');
      const end = moment(this.startDate, 'L').add(6, 'w');
      while (start.isBefore(end)) {
        if (this.chosenWeekdays.includes(start.weekday())) {
          meetings.push(start.format(this.datepickerFormat));
        }
        start.add(1, 'd');
      }
      return meetings;
    },

    highlightedDates() {
      // Highlights all future meeting days
      let allMeetings = [];
      if (this.coffeeConfiguration.period === 0) {
        allMeetings = this.multiDayMeetingDates;
      } else {
        for (let i = 0; i < 4; i += 1) {
          allMeetings.push(
            this.nextMeetingOnMoment.clone().add(this.coffeeConfiguration.period * i, 'd').format(this.datepickerFormat),
          );
        }
      }
      return allMeetings;
    },

    outlinedDates() {
      // Outlines all intro days
      let allIntros = [];
      if (this.coffeeConfiguration.period === 0) {
        allIntros = this.multiDayMeetingDates;
      } else {
        for (let i = 0; i < 4; i += 1) {
          allIntros.push(
            this.nextIntroOnMoment.clone().add(this.coffeeConfiguration.period * i, 'd').format(this.datepickerFormat),
          );
        }
      }
      return allIntros;
    },

    timeOptions() {
      const options = [];
      let meetingsDuration = moment.duration(1, 'day').as('m');
      let timeStart = moment('00:00', 'H:mm');

      // If the next meeting and the next intro are on the same day, only show times 30 minutes after the intro time
      if (this.coffeeConfiguration.next_fixed_meeting_on === this.coffeeConfiguration.next_occurrence_on) {
        timeStart = moment(`${this.coffeeConfiguration.occurrence_hour}:00`, 'H:mm').add(30, 'm');
        meetingsDuration = moment('24:00', 'hh:mm').diff(timeStart, 'minutes');
      }

      for (let i = 0; i < meetingsDuration; i += 15) {
        timeStart.add(i === 0 ? 0 : 15, 'm');
        options.push({
          label: timeStart.format('h:mma'),
          value: timeStart.format('H:mm'),
        });
      }
      return options;
    },
  },

  methods: {
    startCalendarAuth() {
      this.hasClickedAuthLink = true;
    },

    checkCalendarAuth() {
      this.$store.dispatch('getUser', { verifyGoogleIdentity: true });
    },
  },
};
