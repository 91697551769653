import { computed } from 'vue';
import { useStore } from 'vuex';
import * as moment from 'moment';

const useEventsCalendarDayStyles = (day) => {
  const { state, getters } = useStore();

  const specialLabel = computed(() => {
    if (day.isStartDay) {
      return 'START';
    } if (day.isToday) {
      return 'TODAY';
    } if (day.isDay30) {
      return 30;
    } if (day.isDay60) {
      return 60;
    } if (day.isDay90) {
      return 90;
    }
    return null;
  });

  const dayClass = computed(() => {
    const classes = [];
    if (specialLabel.value) {
      classes.push('special');
    }
    if (day.isStartDay) {
      classes.push('start-day');
    } else if (day.isToday) {
      classes.push('today');
    }
    return classes;
  });

  const workDaysLabel = computed(() => {
    if (Number.isInteger(day.id)) {
      if (day.id < 0) {
        return `${-day.id} Work Day${day.id === -1 ? '' : 's'} Before`;
      } if (day.id === 0) {
        return 'Start Date';
      }
      return `Work Day ${day.id + 1}`;
    }
    return null;
  });

  const dateLabel = computed(() => {
    const { startDate } = state.onboardingEventsCalendar;
    const isForTagAsDates = getters['onboardingEventsCalendar/isForTagAsDates'];
    if (startDate && isForTagAsDates) {
      // For viewing a tag as dates instead of weekdays (calendarType: tagAsDates)
      const dateForDay = moment(startDate, 'MM/DD/YY'); // This clones the startDate so we don't mutate it
      let daysRemainingToAdd = day.id;
      while (daysRemainingToAdd !== 0) {
        const numDaysToAdd = daysRemainingToAdd > 0 ? 1 : -1;
        dateForDay.add(numDaysToAdd, 'd');
        if (![0, 6].includes(dateForDay.day())) daysRemainingToAdd -= numDaysToAdd;
      }
      return dateForDay.format('MMM D');
    }
  });

  return { specialLabel, dayClass, workDaysLabel, dateLabel };
};

export default useEventsCalendarDayStyles;
